
@import 'variables';

/* Fira Sans */
@font-face {
    font-family: 'Fira Sans Light';
    font-weight: 300;
    font-style: normal;
    src: url($font-path +  'FiraSans-Light.eot');
    src:
        local('Fira Sans Light'),
        url($font-path +  'FiraSans-Light.eot') format('embedded-opentype'),
        url($font-path +  'FiraSans-Light.woff') format('woff'),
        url($font-path +  'FiraSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans Regular';
    font-weight: 400;
    font-style: normal;
    src: url($font-path +  'FiraSans-Regular.eot');
    src:
        local('Fira Sans Regular'),
        url($font-path +  'FiraSans-Regular.eot') format('embedded-opentype'),
        url($font-path +  'FiraSans-Regular.woff') format('woff'),
        url($font-path +  'FiraSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans Medium';
    font-weight: 500;
    font-style: normal;
    src: url($font-path +  'FiraSans-Medium.eot');
    src:
        local('Fira Sans Medium'),
        url($font-path +  'FiraSans-Medium.eot') format('embedded-opentype'),
        url($font-path +  'FiraSans-Medium.woff') format('woff'),
        url($font-path +  'FiraSans-Medium.ttf') format('truetype');
}


/* Gustan */
@font-face {
    font-family: 'Gustan Black';
    font-weight: 700;
    font-style: normal;
    src: url($font-path +  'Gustan-Black.eot');
    src:
        url($font-path +  'Gustan-Black.eot?#iefix') format('embedded-opentype'),
        url($font-path +  'Gustan-Black.woff') format('woff'),
        url($font-path +  'Gustan-Black.svg') format('svg');
}

@font-face {
    font-family: 'Gustan Bold';
    font-weight: 500;
    font-style: normal;
    src: url($font-path +  'Gustan-Bold.eot');
    src:
        url($font-path +  'Gustan-Bold.eot?#iefix') format('embedded-opentype'),
        url($font-path +  'Gustan-Bold.woff') format('woff'),
        url($font-path +  'Gustan-Bold.svg') format('svg');
}

@font-face {
    font-family: 'Gustan Book';
    font-weight: 300;
    font-style: normal;
    src: url($font-path +  'Gustan-Book.eot');
    src:
        url($font-path +  'Gustan-Book.eot?#iefix') format('embedded-opentype'),
        url($font-path +  'Gustan-Book.woff') format('woff'),
        url($font-path +  'Gustan-Book.svg') format('svg');
}

@font-face {
    font-family: 'Gustan Light';
    font-weight: 200;
    font-style: normal;
    src: url($font-path +  'Gustan-Light.eot');
    src:
        url($font-path +  'Gustan-Light.eot?#iefix') format('embedded-opentype'),
        url($font-path +  'Gustan-Light.woff') format('woff'),
        url($font-path +  'Gustan-Light.svg') format('svg');
}

/* Icons */
@font-face {
    font-family: 'mbie-building-icons';
    font-weight: normal;
    font-style: normal;
    src: url($font-path +  'mbie-building-icons.eot?6898992');
    src:
        url($font-path +  'mbie-building-icons.eot?6898992#iefix') format('embedded-opentype'),
        url($font-path +  'mbie-building-icons.woff?6898992') format('woff'),
        url($font-path +  'mbie-building-icons.ttf?6898992') format('truetype'),
        url($font-path +  'mbie-building-icons.svg?6898992#mbie-building-icons') format('svg');
}
